<template>
  <div class="personal-info-doughnut"
    ref="chart2"></div>
</template>

<script>
  import echarts from 'echarts';
  import ResizeMinxin from '@/mixins/ResizeMixin.js';

  export default {
    props: {
      source: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        echartElm: 'chart2',
        echart: null,
      };
    },
    watch: {
      source() {
        this.echartInit();
      },
    },
    mixins: [ResizeMinxin],
    methods: {
      echartInit() {
        this.echart = echarts.init(this.$refs.chart2, 'macarons');
        const option2 = {
          legend: {
            itemWidth: 8,
            itemHeight: 8,
            icon: 'circle',
            textStyle: {
              color: '#F1F1F3',
            },
            right: 20,
            top: 20,
          },
          grid: {
            y: 100,
          },
          tooltip: {},
          dataset: {
            dimensions: ['product', '下载数量', '浏览数量'],
            source: this.source,
          },
          xAxis: {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: '#4e4e4e',
              },
            },
            axisLabel: {
              fontSize: 12,
              color: '#acacac',
            },
          },
          yAxis: {
            axisLine: {
              lineStyle: {
                color: '#4e4e4e',
              },
            },
            axisLabel: {
              margin: 10,
              fontSize: 12,
              color: '#acacac',
            },
            splitLine: {
              lineStyle: {
                color: '#4e4e4e',
                type: 'dashed',
              },
            },
          },
          series: [
            {
              type: 'bar',
              barWidth: 10,
              itemStyle: {
                barBorderRadius: [5, 5, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: '#7df9fc' },
                    { offset: 1, color: '#02d6dc' },
                  ],
                ),
              },
            },
            {
              type: 'bar',
              barWidth: 10,
              itemStyle: { barBorderRadius: [5, 5, 0, 0] },
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: '#c387ff' },
                  { offset: 1, color: '#9c41f8' },
                ],
              ),
            },
          ],
        };
        this.echart.setOption(option2);
      },
    },
    mounted() {
      this.echartInit();
    },
  };
</script>

<style lang="less" scoped>
  .personal-info-doughnut {
    width: 100%;
    height: 440px;
  }
</style>
