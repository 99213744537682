var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topTenWarp"},[_c('h4',[_vm._v(" 平台运营前十详情 "),_c('data-filter',{staticStyle:{"right":"0","top":"0"},attrs:{"filterOption":_vm.timeRanges},on:{"change":_vm.changeTimeRange}}),_c('data-filter',{staticStyle:{"right":"160px","top":"0"},attrs:{"filterOption":_vm.topTenTabs},on:{"change":_vm.changeTopTenTab}})],1),(!_vm.topTenTab)?_c('el-table',{staticClass:"customer-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableDataProducts,"header-cell-style":{
        'background-color': '#4e4e4e',
        height: '32px',
        color: '#acacac',
      },"cell-style":{
        color: '#FFF',
        'font-size': '12px'
      }}},[_c('el-table-column',{attrs:{"align":"center","type":"index","label":"排名","width":"120"}}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":"产品名称","width":"280"}}),_c('el-table-column',{attrs:{"prop":"brand","align":"center","label":"品牌"}}),_c('el-table-column',{attrs:{"prop":"shelvedAt","align":"center","label":"首次上架"},scopedSlots:_vm._u([{key:"default",fn:function(scoped){return [_vm._v(" "+_vm._s(_vm.moment(scoped.row.shelvedAt).format('YYYY/MM/DD'))+" ")]}}],null,false,1653563984)}),_c('el-table-column',{attrs:{"prop":"browsedCount","align":"center","label":"浏览量"}}),_c('el-table-column',{attrs:{"prop":"browsedUserCount","align":"center","label":"浏览人数(IP)"}}),_c('el-table-column',{attrs:{"prop":"collectedCount","align":"center","label":"收藏人数"}}),_c('el-table-column',{attrs:{"prop":"downloadCount","align":"center","label":"下载数量"}})],1):_c('el-table',{staticClass:"customer-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableDataSearch,"header-cell-style":{
        'background-color': '#4e4e4e',
        height: '32px',
        color: '#acacac',
      },"cell-style":{
        color: '#FFF',
        'font-size': '12px'
      }}},[_c('el-table-column',{attrs:{"align":"center","type":"index","label":"排名","width":"120"}}),_c('el-table-column',{attrs:{"prop":"keyword","align":"center","label":"关键字","width":"280"}}),_c('el-table-column',{attrs:{"prop":"resultCount","align":"center","label":"结果数量"}}),_c('el-table-column',{attrs:{"prop":"totalSearchCount","align":"center","label":"总搜索量"}}),_c('el-table-column',{attrs:{"prop":"yearSearchCount","align":"center","label":"本年搜索"}}),_c('el-table-column',{attrs:{"prop":"monthSearchCount","align":"center","label":"本月搜索"}}),_c('el-table-column',{attrs:{"prop":"weekSearchCount","align":"center","label":"本周搜索"}}),_c('el-table-column',{attrs:{"prop":"todaySearchCount","align":"center","label":"今日搜索"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }