<template>
  <div class="chart2Warp">
    <div class="lineChart__title">
      <span></span>
      一级分类下载占比
    </div>
    <data-filter style="right: 0px"
      :filterOption="timeRanges"
      @change="changeTimeRange"></data-filter>
    <pie-chart
      :width="300"
      :seriesData="seriesData"
      :legendData="legendData"
      :subtext="subtext">
    </pie-chart>
  </div>
</template>

<script>
  /**
   * @description 端口占比统计图
   * @author Star
   * @date 2020/08/24
   */
  import * as api from '@/api';
  import PieChart from './PieChart.vue';
  import DataFilter from './DataFilter.vue';

  export default {
    components: {
      PieChart,
      DataFilter,
    },
    data() {
      return {
        subtext: '',
        timeRanges: [
          {
            txt: '本周',
            value: 0,
          },
          {
            txt: '本月',
            value: 1,
          },
          {
            txt: '本年',
            value: 2,
          },
        ],
        option: '浏览次数端口占比',
        timeRange: 0,
        legendData: [], // 图例数据
        seriesData: [],
      };
    },
    watch: {
      timeRange() {
        this.getDownloadRate();
      },
    },
    methods: {
      changeTimeRange(selected) {
        this.timeRange = selected;
      },
      // 一级分类下载量两占比
      async getDownloadRate() {
        const result = await api.getDownloadRate({
          timeRange: this.timeRange.toString(),
        });
        if (!api.error(result)) {
          const { data } = result;
          let dataFormat = data.filter(((item) => item.amount > 0)).sort((a, b) => b.amount - a.amount);
          console.log(dataFormat);
          if (dataFormat.findIndex((item) => item.amount > 0) < 0) {
            dataFormat = [{ txt: '总下载量', amount: 0 }];
          } else if (dataFormat.length > 6) {
            const a = dataFormat.splice(5);
            const b = [{ txt: '其他', amount: 0 }];
            for (let i = 0; i < a.length; i++) {
              b[0].amount += a[i].amount;
            }
            dataFormat = [...dataFormat, ...b];
          }
          this.seriesData = dataFormat.map((item, index) => {
            if (index === 0) {
              return {
                value: item.amount,
                name: item.txt,
                itemStyle: { color: 'rgb(2,213,220)' },
              };
            } if (index === 1) {
              return {
                value: item.amount,
                name: item.txt,
                itemStyle: { color: 'rgb(157,65,248)' },
              };
            }
            return {
              value: item.amount,
              name: item.txt,
            };
          });
          this.legendData = dataFormat.map((item) => item.txt);
          this.subtext = '总下载量';
        }
      },
      handleCommand(command) {
        this.option = command;
        this.getVisitClientType();
      },
    },
    mounted() {
      this.getDownloadRate();
    },
  };
</script>

<style lang="less" scoped>
  .chart2Warp {
    width: 400px;
    position: relative;
    .lineChart__title {
      cursor: pointer;
      font-size: 14px;
      position: absolute;
      top: 18px;
      left: 16px;
      font-size: 16px;
      padding-left: 10px;
      z-index: 2;
      .el-dropdown-link {
        color: #ffffff;
        font-size: 16px;
      }
      > span:nth-of-type(1) {
        display: inline-block;
        width: 4px;
        height: 22px;
        background-color: #02d6dc;
        border-radius: 2px;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }
</style>
