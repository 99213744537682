<template>
  <div class="productStatisticsWarp">
    <div class="productStatistics-top">
      <div class="productStatistics-top__left">
        <download-statistics-chart></download-statistics-chart>
      </div>
      <div class="productStatistics-top__right">
        <class-statistic></class-statistic>
      </div>
    </div>
    <div class="productStatistics-bottom">
      <TopTen></TopTen>
    </div>
  </div>
</template>

<script>
  import TopTen from './TopTen.vue';
  import ClassStatistic from './ClassStatistic.vue';
  import DownloadStatisticsChart from './DownloadStatisticChart.vue'; // 端口占比统计

  export default {
    components: {
      TopTen,
      DownloadStatisticsChart,
      ClassStatistic,
    },
  };
</script>

<style lang="less">
  .productStatisticsWarp {
    min-height: 100%;
    color: #ffffff;
    background-image: linear-gradient(90deg, #293944 0%, #29282d 100%);
    .productStatistics-top {
      display: flex;
      border-bottom: 1px solid #4a4a4a;
      .productStatistics-top__left {
        width: 400px;
        .personal-info-doughnut {
          height: 400px;
        }
      }
      .productStatistics-top__right {
        flex: 1;
      }
    }
    .productStatistics-bottom {
      padding: 30px 24px 0 24px;
    }
    .el-loading-mask {
      background-color: transparent;
    }
  }
</style>
