<template>
  <div class="topTenWarp">
    <h4>
      平台运营前十详情
      <data-filter style="right: 0; top: 0"
        :filterOption="timeRanges"
        @change="changeTimeRange"></data-filter>
      <data-filter style="right: 160px;top: 0"
        :filterOption="topTenTabs"
        @change="changeTopTenTab"></data-filter>
    </h4>
    <el-table v-if="!topTenTab"
      :data="tableDataProducts"
      class="customer-table"
      :header-cell-style="{
          'background-color': '#4e4e4e',
          height: '32px',
          color: '#acacac',
        }"
      :cell-style="{
          color: '#FFF',
          'font-size': '12px'
        }"
      style="width: 100%">
      <el-table-column align="center"
        type="index"
        label="排名"
        width="120">
      </el-table-column>
      <el-table-column prop="name"
        align="center"
        label="产品名称"
        width="280">
      </el-table-column>
      <el-table-column prop="brand"
        align="center"
        label="品牌">
      </el-table-column>
      <el-table-column prop="shelvedAt"
        align="center"
        label="首次上架">
        <template slot-scope="scoped">
          {{moment(scoped.row.shelvedAt).format('YYYY/MM/DD')}}
        </template>
      </el-table-column>
      <el-table-column prop="browsedCount"
        align="center"
        label="浏览量">
      </el-table-column>
      <el-table-column prop="browsedUserCount"
        align="center"
        label="浏览人数(IP)">
      </el-table-column>
      <el-table-column prop="collectedCount"
        align="center"
        label="收藏人数">
      </el-table-column>
      <el-table-column prop="downloadCount"
        align="center"
        label="下载数量">
      </el-table-column>
    </el-table>
    <el-table :data="tableDataSearch"
      v-else
      class="customer-table"
      :header-cell-style="{
          'background-color': '#4e4e4e',
          height: '32px',
          color: '#acacac',
        }"
      :cell-style="{
          color: '#FFF',
          'font-size': '12px'
        }"
      style="width: 100%">
      <el-table-column align="center"
        type="index"
        label="排名"
        width="120">
      </el-table-column>
      <el-table-column prop="keyword"
        align="center"
        label="关键字"
        width="280">
      </el-table-column>
      <el-table-column prop="resultCount"
        align="center"
        label="结果数量">
      </el-table-column>
      <el-table-column prop="totalSearchCount"
        align="center"
        label="总搜索量">
      </el-table-column>
      <el-table-column prop="yearSearchCount"
        align="center"
        label="本年搜索">
      </el-table-column>
      <el-table-column prop="monthSearchCount"
        align="center"
        label="本月搜索">
      </el-table-column>
      <el-table-column prop="weekSearchCount"
        align="center"
        label="本周搜索">
      </el-table-column>
      <el-table-column prop="todaySearchCount"
        align="center"
        label="今日搜索">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  /**
   * 平台运营前十详情
   */
  import * as api from '@/api';
  import moment from 'moment';
  import DataFilter from './DataFilter.vue';

  export default {
    components: {
      DataFilter,
    },
    data() {
      return {
        moment,
        tableDataProducts: [],
        tableDataSearch: [],
        timeRange: 0,
        topTenTab: 0,
        timeRanges: [
          {
            txt: '本周',
            value: 0,
          },
          {
            txt: '本月',
            value: 1,
          },
          {
            txt: '本年',
            value: 2,
          },
        ],
        topTenTabs: [
          {
            txt: '产品统计',
            value: 0,
          },
          {
            txt: '搜索统计',
            value: 1,
          },
        ],
      };
    },
    created() {
      this.getTopTenProducts();
      this.getTopTenSearch();
    },
    watch: {
      topTenTab(val) {
        if (val) {
          this.getTopTenSearch();
        } else {
          this.getTopTenProducts();
        }
      },
      timeRange() {
        if (this.topTenTab) {
          this.getTopTenSearch();
        } else {
          this.getTopTenProducts();
        }
      },
    },
    methods: {
      async getTopTenProducts() {
        const topTenProducts = await api.getTopTenProducts(
          { timeRange: this.timeRange },
        );
        if (!api.error(topTenProducts)) {
          this.tableDataProducts = topTenProducts.data;
        }
      },
      async getTopTenSearch() {
        const topTenSearch = await api.getTopTenSearch(
          { timeRange: this.timeRange },
        );
        if (!api.error(topTenSearch)) {
          console.log(topTenSearch);
          this.tableDataSearch = topTenSearch.data;
        }
      },
      changeTimeRange(selected) {
        this.timeRange = selected;
      },
      changeTopTenTab(selected) {
        this.topTenTab = selected;
      },
    },
  };
</script>

<style lang="less">
  .topTenWarp {
    h4 {
      font-size: 16px;
      margin-bottom: 17px;
      font-weight: 500;
      position: relative;
      .topTenTabs {
        right: 220px;
      }
    }
    .el-table {
      background: transparent;
      border: 0;
    }
    .el-table tr {
      background: transparent;
    }
    .el-table td {
      height: 32px;
      padding: 0;
      border-bottom: 1px solid #4e4e4e;
    }

    // 去掉表格单元格边框
    .customer-table th {
      border: none;
    }
    .customer-table td,
    .customer-table th.is-leaf {
      border: none;
    }
    .el-table tbody tr:hover > td {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
    .customer-table::before {
      width: 0;
    }
  }
</style>
