import { render, staticRenderFns } from "./ClassStatistic.vue?vue&type=template&id=89cb5a36&scoped=true&"
import script from "./ClassStatistic.vue?vue&type=script&lang=js&"
export * from "./ClassStatistic.vue?vue&type=script&lang=js&"
import style0 from "./ClassStatistic.vue?vue&type=style&index=0&id=89cb5a36&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89cb5a36",
  null
  
)

export default component.exports