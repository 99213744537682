<template>
  <div class="chart2Warp">
    <div class="lineChart__title">
      <span></span>
      一级分类数据统计
    </div>
    <data-filter style="right: 180px"
      :filterOption="timeRanges"
      @change="changeTimeRange">
    </data-filter>
    <bar-chart :source="source"></bar-chart>
  </div>
</template>

<script>
  import * as api from '@/api';
  import BarChart from './BarChart.vue';
  import DataFilter from './DataFilter.vue';

  export default {
    components: {
      BarChart,
      DataFilter,
    },
    data() {
      return {
        source: [],
        timeRange: 0,
        timeRanges: [
          {
            txt: '本周',
            value: 0,
          },
          {
            txt: '本月',
            value: 1,
          },
          {
            txt: '本年',
            value: 2,
          },
        ],
      };
    },
    watch: {
      timeRange() {
        this.getClassStatistic();
      },
    },
    created() {
      this.getClassStatistic();
    },
    methods: {
      changeTimeRange(selected) {
        this.timeRange = selected;
      },
      async getClassStatistic() {
        const reslut = await api.getClassStatistic({
          timeRange: this.timeRange,
        });
        if (!api.error(reslut)) {
          const browseCount = reslut.data.browseCount.items;
          const downloadCount = reslut.data.downloadCount.items;
          let source = [];
          for (let i = 0; i < browseCount.length; i++) {
            source.push({
              product: browseCount[i].txt.length > 6 ? `${browseCount[i].txt.substring(0, 5)}...` : browseCount[i].txt,
              下载数量: downloadCount[i].amount,
              浏览数量: browseCount[i].amount,
            });
          }
          source = source.filter((item) => item['浏览数量'] + item['下载数量'] > 0).sort((a, b) => ((b['下载数量'] + b['浏览数量']) - (a['下载数量'] + a['浏览数量'])));
          if (source.length > 6) {
            const a = source.splice(5);
            const b = [{ product: '其他', 下载数量: 0, 浏览数量: 0 }];
            a.forEach((element) => {
              b[0]['下载数量'] += element['下载数量'];
              b[0]['浏览数量'] += element['浏览数量'];
            });
            this.source = [...source, ...b];
            return;
          }
          this.source = source;
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .chart2Warp {
    position: relative;
    .lineChart__title {
      cursor: pointer;
      font-size: 14px;
      position: absolute;
      top: 18px;
      left: 90px;
      font-size: 16px;
      padding-left: 10px;
      z-index: 2;
      .el-dropdown-link {
        color: #ffffff;
        font-size: 16px;
      }
      > span:nth-of-type(1) {
        display: inline-block;
        width: 4px;
        height: 22px;
        background-color: #02d6dc;
        border-radius: 2px;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }
</style>
